<template>
    <div>
        <Navigation/>
            <main class="main">
            <!-- {{ statusOrder }} -->
                <b-overlay :show="isLoadingPage || !statusOrder " rounded  >
                    <!-- <div v-if="!statusOrder">                         
                        <figure class="thankyou__picture">
                            <img :src="require('@/assets/images/not-found.png')" alt="illustration-1" class="not-found__img">
                        </figure>
                        <h1 class="title"> {{ $t('thankYou.pageNotFound') }} </h1>
                        <p class="paragraph"> {{ $t('thankYou.thePageYouAreLookingForDoesNotExist') }} </p>
                        <a class="btn-back" @click="goToList" > {{ $t('thankYou.goToOurList') }} </a>                                      

                    </div>  -->
                    <div class="thankyou container" v-if="statusOrder && statusOrder.transactionstatus.name === 'Approved' " >
                        <figure class="thankyou__picture">
                            <img :src="require('@/assets/images/check.png')" alt="illustration-1" class="thankyou__img">
                        </figure>
                        <h1 class="title"> {{ $t('thankYou.thankYou') }} </h1>
                        <p class="paragraph"> {{ $t('thankYou.thanYouForYourPayment') }} </p>
                        <a class="btn-back" href="https://www.marholidayscaribe.com/" > {{ $t('thankYou.backToHome') }}  </a>
                    </div>
                    <div class="thankyou container" v-if="statusOrder && statusOrder.transactionstatus.name === 'Pending' ">
                        <figure class="thankyou__picture">
                        <img :src="require('@/assets/images/credit-card.png')" alt="illustration-1" class="payment__img">
                        </figure>
                        <h1 class="title">{{ $t('thankYou.thankYou') }}</h1>
                        <p class="paragraph"> {{ $t('thankYou.thanYouForYourPreference') }} </p>
                        <a class="btn-back" href="https://www.marholidayscaribe.com/" target="_blank" > {{ $t('thankYou.backToHome') }} </a>   
                    </div>
                    <div  class="thankyou container" v-if="statusOrder && statusOrder.transactionstatus.name === 'Denied' " >
                        <figure class="thankyou__picture">
                        <img :src="require('@/assets/images/credit-card.png')" alt="illustration-1" class="payment__img">
                        </figure>
                        <h1 class="title"> {{ $t('thankYou.thankYou') }} </h1>
                        <p class="paragraph warning"> {{ $t('thankYou.thanYouForYourPreferencePaymentDenied') }} </p>
                        <a class="btn-back" href="https://www.marholidayscaribe.com/" target="_blank" > {{ $t('thankYou.backToHome') }} </a>  
                    </div>
                </b-overlay>                           
            </main>
        <Footer/>
    </div>
</template>

<script>
import {  mapActions, mapMutations } from 'vuex'

import Navigation from '@/modules/shop/components/experiences/Navigation'
import Footer from '@/modules/shop/components/experiences/Footer'
import { market } from '@/modules/shop/mixins/market'

    export default {
        mixins:[market],
        created(){
            document.title = `${process.env.VUE_APP_NAME } | Thank you`  // 87660
            this.init()
        },
        mounted(){
        },
        components: {     
            Navigation,           
            Footer,        
        }, 
        data(){
            return {
                isLoadingPage: true,
                statusOrder: null,
            }
        },
        methods:{
            ...mapActions('shop',['checkOrderStatusPayment']),
            ...mapMutations('shop',['resetCartCustomerBreakdownOrder']), 
            ...mapMutations('auth',['setDataQueryParams']), 

            async init(){
                this.statusOrder = null
                this.isLoadingPage = true
                const idOrderIsValid = this.isValidIdOrder() //verifico si es valido                

                if( !idOrderIsValid ){
                    this.$router.push({ name:'not-found'})                     
                }
                if(idOrderIsValid){
                    const idOrder = this.$route.params.order
                    this.statusOrder = await this.checkOrderStatusPayment(idOrder) 
                    console.log(this.statusOrder)
                }
                this.isLoadingPage = false
                //limpio lo que hay en el state de queryParams, en shop orderCreated, los datos del cliente, Cart               
                this.resetQueryParamsOrderCreatedCustomerData() 
            },
            resetQueryParamsOrderCreatedCustomerData(){
                if(this.statusOrder){ //si status order no es nulo
                    const { transactionstatus } = this.statusOrder
                    if(transactionstatus.name === 'Approved'){
                        this.resetCartCustomerBreakdownOrder()
                        this.setDataQueryParams(null)
                    }
                }
            },
            isValidIdOrder(){    
                const idOrder = this.$route.params?.order                
                const isNumber = idOrder ? this.isANumber( idOrder ) : false              
                return isNumber && idOrder > 0 // si es numero y que sea mayor a 0
            },     
            goToList(){      
				this.$router.push({ name:'results'})
			},      
        }
    }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/thank-you-page.scss";
</style>